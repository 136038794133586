export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(main)/(marketing)/(landing-pages)": [56,[3,11,13],[4]],
		"/(no-header-footer)/activation": [75,[15]],
		"/(main)/(blog)/blog": [50,[10]],
		"/(main)/(blog)/blog/articles/[slug]": [51,[10]],
		"/(main)/(blog)/blog/categories": [52,[10]],
		"/(main)/(blog)/blog/categories/[category]": [53,[10]],
		"/(no-header-footer)/changer-email": [76,[15]],
		"/(no-header-footer)/changer-mot-de-passe": [77,[15]],
		"/(main)/(account)/compte-de-paiement": [19,[3,5,6],[4]],
		"/(main)/(account)/compte-de-paiement/historique/[...slug]": [20,[3,5,6],[4]],
		"/(main)/(account)/compte-de-paiement/recharge": [21,[3,5,6],[4]],
		"/(main)/(account)/compte-de-paiement/virement": [22,[3,5,6],[4]],
		"/(main)/(account)/compte/[...slug]": [18,[3,5],[4]],
		"/(no-header-footer)/confirmer-email": [78,[15]],
		"/(main)/contact": [~64,[3],[4]],
		"/(main)/creer-un-compte": [65,[3],[4]],
		"/(main)/(marketing)/(landing-pages)/defiscalisation-crowdfunding": [57,[3,11,13],[4]],
		"/(main)/(marketing)/(hero)/entreprises/levez-des-fonds/[slug]": [55,[3,11,12],[4]],
		"/(main)/evenements": [66,[3],[4]],
		"/(main)/(account)/fil-actualite": [23,[3,5],[4]],
		"/(main)/(account)/fiscalite": [24,[3,5,7],[4]],
		"/(main)/(account)/fiscalite/attestations-fiscales": [25,[3,5,7],[4]],
		"/(main)/(account)/fiscalite/attestations-propriete": [26,[3,5,7],[4]],
		"/(main)/(account)/fiscalite/dispense-prelevement-forfaitaire-non-liberatoire/[...slug]": [27,[3,5,7],[4]],
		"/(main)/(account)/fiscalite/non-residents": [28,[3,5,7],[4]],
		"/(main)/ghost": [67,[3],[4]],
		"/(kyc)/inscription": [17,[2]],
		"/(main)/(marketing)/(landing-pages)/investir-en-crowdfunding-immobilier": [58,[3,11,13],[4]],
		"/(main)/(marketing)/(landing-pages)/investir-fonds-investissement": [59,[3,11,13],[4]],
		"/(main)/(marketing)/(landing-pages)/investir-startup": [60,[3,11,13],[4]],
		"/(no-header-footer)/investir/[...slug]/conditions": [79,[15,16]],
		"/(no-header-footer)/investir/[...slug]/file-attente": [80,[15,16]],
		"/(no-header-footer)/investir/[...slug]/informations/[...slug]": [81,[15,16]],
		"/(no-header-footer)/investir/[...slug]/modalites": [82,[15,16]],
		"/(no-header-footer)/investir/[...slug]/souscription-enregistree/[...slug]": [83,[15,16]],
		"/(main)/(marketing)/(landing-pages)/investissement-startup-reduction-impots": [61,[3,11,13],[4]],
		"/(main)/(marketing)/(no-hero)/juridique/[slug]": [63,[3,11,14],[4]],
		"/(no-header-footer)/oauth": [84,[15]],
		"/(main)/(account)/portefeuille": [29,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/actions": [31,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/fonds": [32,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/obligations": [33,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/tableau-de-bord": [34,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/titres-participatifs": [35,[3,5,8],[4]],
		"/(main)/(account)/portefeuille/[...slug]": [30,[3,5,8],[4]],
		"/(main)/projets": [70,[3],[4]],
		"/(main)/projets/acces-prives": [71,[3],[4]],
		"/(main)/projets/crowdfunding": [72,[3],[4]],
		"/(main)/projets/fonds": [73,[3],[4]],
		"/(main)/projet/acces-prive": [69,[3],[4]],
		"/(main)/projet/[...slug]": [68,[3],[4]],
		"/(main)/(marketing)/(landing-pages)/reduction-immediate-crowdfunding": [62,[3,11,13],[4]],
		"/(main)/(account)/souscription/[subscriptionID]/assemblees": [36,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/assemblees/[...slug]": [37,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/communications": [38,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/communications/[communicationID]": [39,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/documents": [40,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/echeancier": [41,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/echeancier/[echeancierID]/historique": [42,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/news": [43,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/questions": [44,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/recapitulatif": [45,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/reportings": [46,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/reportings/[reportingID]": [47,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/sondages": [48,[9]],
		"/(main)/(account)/souscription/[subscriptionID]/sondages/[pollID]": [49,[9]],
		"/(main)/statistiques": [74,[3],[4]],
		"/(main)/(marketing)/(hero)/[...slug]": [54,[3,11,12],[4]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';